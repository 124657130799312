import { useTranslation } from "@health/i18n";
import { FormActions, FormCard, Grid, PageWrapper } from "@health/ui";
import React, { VFC } from "react";
import { FormProvider } from "react-hook-form";
import NumericField from "../NumericField";
import { useOrderTimeoutFormSettings } from "./useOrderTimeoutsFormHook";

const OrderTimeoutsForm: VFC = () => {
  const { t } = useTranslation("Admin");
  const { defaults, methods, isSubmitting, loading, onSubmit, handleSubmit, handleNavigation } = useOrderTimeoutFormSettings();
  return (
    <PageWrapper
      actions={
        <FormActions
          hasCancel
          hasSave
          loadingIndicators={{ save: isSubmitting }}
          onSave={handleSubmit(onSubmit)}
          onNavigation={handleNavigation}
        ></FormActions>
      }
    >
      <FormProvider {...methods}>
        <FormCard loading={loading} title={t("Order Timeouts")} doYouHaveData={true}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} lg={6} rowSpacing={2} flexDirection='column'>
              <Grid item>
                <NumericField
                  required
                  label={t("Time to accept new order (Minutes)")}
                  defaultValue={defaults?.orderAcceptanceTimeout}
                  fieldName='orderAcceptanceTimeout'
                />
              </Grid>
              <Grid item>
                <NumericField
                  required
                  label={t("Time to get yusur approval (Minutes)")}
                  defaultValue={defaults?.orderErxHubApprovalTimeout}
                  fieldName='orderErxHubApprovalTimeout'
                />
              </Grid>
              <Grid item>
                <NumericField
                  label={t("Erx Hub approval resubmition timeout (Minutes)")}
                  defaultValue={defaults?.orderErxHubApprovalResubmitTimeout}
                  fieldName='orderErxHubApprovalResubmitTimeout'
                />
              </Grid>
              <Grid item>
                <NumericField
                  required
                  label={t("Order payment timeout (Minutes)")}
                  defaultValue={defaults?.orderPaymentTimeout}
                  fieldName='orderPaymentTimeout'
                />
              </Grid>
            </Grid>
          </form>
        </FormCard>
      </FormProvider>
    </PageWrapper>
  );
};

export default OrderTimeoutsForm;
