import { i18next } from "@health/i18n";
import { BulkFile, BulkFilesOrderDirection, BulkFileSortField } from "@health/queries";
import { ArrowIcon, CustomTableColumnProps, format, StyledButton } from "@health/ui";
import { DownloadfileButton } from "../components/DownloadFileButton";

type ColumnProps = {
  t: i18next.TFunction;
  currentLang: string;
  handleDetailClick: (file: BulkFile) => void;
};

export const getFileListColumns = ({ t, currentLang, handleDetailClick }: ColumnProps): CustomTableColumnProps<BulkFile>[] => [
  {
    key: "createdBy",
    header: t("CreatedBy"),
    isSortable: true,
    accessor: row => row.createdBy?.firstName + " " + (row.createdBy?.lastName ?? ""),
    sortDirection: BulkFilesOrderDirection.Desc,
    sortColumnEnum: BulkFileSortField.CreatedDate,
  },
  {
    key: "createdDate",
    header: t("Creation Date"),
    isSortable: true,
    sortDirection: undefined,
    accessor: row => format(row?.createdDate, "DD/MM/YYYY hh:mm A"),
  },
  {
    key: "recordsCount",
    header: t("Record Count"),
    accessor: "recordsCount",
  },
  {
    key: "failedRecordCount",
    header: t("Failed Record Count"),
    accessor: "failedRecordCount",
  },
  {
    key: "processedRecordCount",
    header: t("Processed Record Count"),
    accessor: "processedRecordCount",
  },
  {
    key: "processingStatus",
    header: t("Processing Status"),
    accessor: row => t(row.processingStatus ?? "UNKNOWN"),
  },
  {
    key: "updatedDate",
    header: t("Update Date"),
    isSortable: true,
    accessor: row => format(row?.updatedDate, "DD/MM/YYYY hh:mm A"),
    sortDirection: BulkFilesOrderDirection.Desc,
    sortColumnEnum: BulkFileSortField.UpdatedDate,
  },
  {
    key: "Download",
    header: t("Download"),
    isSortable: true,
    sortDirection: undefined,
    accessor: row => <DownloadfileButton fileId={row.id} />,
  },
  {
    key: "details",
    header: t("Details"),
    isSortable: true,
    sortDirection: undefined,
    accessor: row => (
      <StyledButton
        onClick={() => handleDetailClick(row)}
        color='inherit'
        size='medium'
        type='submit'
        endIcon={<ArrowIcon color='error' transform={currentLang === "en" ? `rotate(-90)` : `rotate(90)`} />}
      >
        {t("Details")}
      </StyledButton>
    ),
  },
];
