import { useTranslation } from "@health/i18n";
import {
  BulkProcessingStatus,
  PrescriptionOrderBulkFileRecord,
  PrescriptionOrderRecordsOrderDirection,
  PrescriptionOrderRecordSortField,
  QueryPrescriptionOrderBulkFileRecordsArgs,
  useDataGridState,
  usePrescriptionOrderBulkFileRecordsQuery,
} from "@health/queries";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { getBulkUploadFileColumns } from "./BulkFileDetailsColumns.component";
import { useFileListDetailsFilter } from "./FileListDetailsFilter.component";

export const useBulkFileDetailsHook = () => {
  const { t } = useTranslation("Admin");
  const params = useParams();

  const inputs: QueryPrescriptionOrderBulkFileRecordsArgs = {
    first: 10,
    after: null,
    before: null,
    last: null,
    filters: { bulkFileId: params.id },
    sortBy: { direction: PrescriptionOrderRecordsOrderDirection.Desc, field: PrescriptionOrderRecordSortField.CreatedDate },
  };

  const { data, isLoading, pageSize, doChangePageSize, doGoToNext, doGoToPrevious, setVariables } = useDataGridState({
    useCustomQuery: usePrescriptionOrderBulkFileRecordsQuery,
    input: inputs,
  });

  const bulkFiles = data?.prescriptionOrderBulkFileRecords?.edges?.map(item => item?.node) as PrescriptionOrderBulkFileRecord[];
  const pageInfo = data?.prescriptionOrderBulkFileRecords?.pageInfo;

  const [activeFilters, setActiveFilters] = useState<Record<string, any>>({});

  const [isFilterOpened, setIsFilterOpened] = useState(false);

  const handleToggleFilters = () => {
    setIsFilterOpened(state => !state);
  };

  const handleApplyFilters = filteredData => {
    const { createdBy, created, createdTo, updated, updatedTo } = filteredData;
    setActiveFilters({
      ...filteredData,
      createdBy: createdBy ? { id: createdBy.id, firstName: createdBy.firstName, lastName: createdBy.lastName } : null,
    });
    setVariables(state => ({
      ...state,
      first: pageSize,
      after: null,
      last: null,
      before: null,
      filters: {
        bulkFileId: params.id,
        processingStatus: BulkProcessingStatus[filteredData?.processingStatus],
        createdDate:
          created || createdTo
            ? {
                lte: createdTo || null,
                gte: created || null,
              }
            : null,
        updatedDate:
          updated || updatedTo
            ? {
                lte: updatedTo || null,
                gte: updated || null,
              }
            : null,
      },
    }));
  };

  const activeFiltersDisplay = activeFilters;

  const { filters } = useFileListDetailsFilter(activeFilters);

  const handleGotoNext = () => {
    doGoToNext();
  };
  const handleGoToPrevious = () => {
    doGoToPrevious();
  };

  const handlePageSizeChange = (size: number): void => {
    doChangePageSize(size);
  };

  return {
    // id: params.id,
    columns: getBulkUploadFileColumns({ t }),
    pageSize,
    pageInfo,
    isLoading,
    bulkFiles,
    activeFiltersDisplay,
    isFilterOpened,
    activeFilters,
    filters,
    handleApplyFilters,
    handleToggleFilters,
    handleGotoNext,
    handleGoToPrevious,
    handlePageSizeChange,
  };
};
